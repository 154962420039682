o<template>
  <div class="home">
  <div class="pt-5 pl-10 flex flex-row">
      <span @click="toggleTag('waterfalls')" class="imgTag inline-block bg-gray-200 rounded-full px-3 py-1 text-gray-700 mr-2 mb-2 font-semibold cursor-pointer select-none" :class="[selectedTags.includes('waterfalls') ? 'selected' : '']">#waterfalls</span>
  </div>
  <div class="p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-5">
      <div v-for="ph in filterImages" :key="ph.id" class="rounded flex flex-col overflow-hidden shadow-lg dark:bg-gray-700">
        <div class="flex-1">
          <img class="cursor-pointer" :src="ph.thumbnail" :alt="ph.title" @click="showModal(ph)">
        </div>
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2 dark:text-gray-300">{{ph.title}}</div>
        </div>
        <div class="px-6 pt-4 pb-2">
          <span v-for="(t, i) in ph.tags" :key="i" class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 dark:bg-gray-400 dark:text-gray-800 imgTag" :class="[selectedTags.includes(t) ? 'selected' : '']">#{{t}}</span>          
        </div>
      </div>    
    </div>
  </div>

   <div :class="['modal', 'h-screen', 'w-full', 'fixed', 'left-0', 'top-0', 'flex', 'flex-1', 'justify-center', 'bg-black' ,'bg-opacity-50', isModalVisible ? '' : 'hidden']">
    <!-- modal -->
    <div class="bg-white rounded shadow-lg w-11/12 dark:bg-gray-800">
      <!-- modal header -->
      <div class="border-b px-4 py-2 flex justify-between items-center dark:border-gray-700">
        <h3 class="font-semibold text-lg dark:text-gray-200">{{selected.title}}</h3>
        <button class="text-black close-modal dark:text-gray-200" @click="isModalVisible = false;">&cross;</button>
      </div>
      <!-- modal body -->
      <div class="p-3">
        <img :src="selected.path" :alt="selected.title" class="max-w-full max-h-full object-contain"/>  
      </div>
      <!-- <div class="flex justify-end items-center w-100 border-t p-3">
        <button class="bg-red-600 hover:bg-red-700 px-3 py-1 rounded text-white mr-1 close-modal">Close</button>        
      </div> -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {    
    //HelloWorld,
  },
  data: function() {
    return{
      images: [],
      selected: {},
      selectedTags: [],
      isModalVisible: false
    }
  },
  computed: {
    filterImages: function(){
      const self = this;
      if (self.selectedTags.length > 0)
        return this.$store.state.images.filter(img =>  img.tags.some(t => self.selectedTags.includes(t)));
      else
        return this.images;
    }
  },
  methods:{
    showModal: function(img) {
        this.isModalVisible = true;
        this.selected = img;
    },
    toggleTag: function(tag){
      var idx = this.selectedTags.indexOf(tag);
        if (idx)
          this.selectedTags.push(tag);
        else
          this.selectedTags.splice(idx, 1);
        
        this.filter();
    },
    filter: function() {
      
    }
  },
  mounted: function(){
      this.images = this.$store.state.images
  }
}
</script>

<style>
  .imgTag.selected{
    @apply bg-green-300 text-gray-700;
  }
</style>