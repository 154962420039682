<template>  
      <nav class="flex flex-row md:justify-between bg-gray-100 p-5 dark:bg-gray-900 dark:text-gray-300">        
          <ul class="flex flex-row md:w-5/6">
              <li class="pr-5"><router-link to="/">Home</router-link></li>
              <li class="pr-5"><router-link to="/about">About</router-link></li>
              <li><a>Contact</a></li>
          </ul>
          <ul class="flex flex-row md:w-1/6">
              <li class="pr-5">FB</li>
              <li class="pr-5">IG</li>
          </ul>
      </nav>  
</template>