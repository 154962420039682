import { createStore } from 'vuex'

export default createStore({
  state: {
    images: [
      { id: 5, title: "Middle Tennessee Creek", path: `${process.env.BASE_URL}portfolio/landscape/P1024380.jpg`, thumbnail: `${process.env.BASE_URL}portfolio/landscape/thumbnails/P1024380.jpg`, tags: ['landscape', 'waterfalls']},
      { id: 4, title: "Greeter Falls", path: `${process.env.BASE_URL}portfolio/landscape/P1024287.jpg`, thumbnail: `${process.env.BASE_URL}portfolio/landscape/thumbnails/P1024287.jpg`, tags: ['landscape', 'waterfalls']},      
      { id: 3, title: "Louisiana State Capitol", path: `${process.env.BASE_URL}portfolio/landscape/P1011854.jpg`, thumbnail: `${process.env.BASE_URL}portfolio/landscape/thumbnails/P1011854.jpg`, tags: ['landscape', 'buiding']},
      { id: 2, title: "Flower", path: `${process.env.BASE_URL}portfolio/landscape/P3030254.jpg`, thumbnail: `${process.env.BASE_URL}portfolio/landscape/thumbnails/P3030254.jpg`, tags: ['landscape', 'plants']},
      { id: 1, title: "Mendenhall Glacier", path: `${process.env.BASE_URL}portfolio/landscape/P1010754.jpg`, thumbnail: `${process.env.BASE_URL}portfolio/landscape/thumbnails/P1010754.jpg`, tags: ['landscape', 'glacier']},
    ]
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
