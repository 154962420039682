<template>
  <div class="">
    <main-header />
    <router-view/>
  </div>
</template>

<script>
import MainHeader from '@/components/MainHeader.vue'
export default {
  components: {
    MainHeader
  }
}
</script>

